import { SagaIterator } from 'redux-saga';
import { call, put, select, delay } from 'redux-saga/effects';
import Api from '../../../modules/utils/API';
import {
  errorsGlobalError,
  fetchCouponsSuccessAction,
  fetchLaboratoriesSuccessAction,
  fetchOrderImagesSuccessAction,
  fetchOrderListForUserSuccessAction,
  fetchProductGroupsSuccessAction,
  fetchProductPricingListSuccessAction,
  fetchProductsSuccessAction,
  fetchUserAccountBalanceSuccessAction,
  fetchUserPricingSuccessAction,
  getGlobalShopSettingsSuccess,
  setPayDocumentsSuccessAction,
  setSalesAutomationAction,
  setSelectedPriceListAction,
  showNotifySuccess,
  userHasEnoughInvoicingDataSuccessAction
} from '../../../modules/actions';
import ApiErrors from '../../../modules/utils/API/APIErrors';
import {
  ICreateGlobalShopSettingsParams,
  IFetchOrderListForUserParams,
  ICreateProductPriceListParams,
  IPriceItem,
  ISagaAction,
  IShopCoupon,
  IUpdateGlobalShopSettingsParams,
  IUpdatePriceValue,
  IDeleteProductPriceListParams,
  ISalesConfigBulkUpdateAction,
  ICancelOrderParams,
  IShopOrder
} from '../../../modules/types';
import {
  getProductPrisingListSelector,
  getProductUserPricing,
  getSelectedPriceListSelector,
  getShopCouponsSelector,
  getShopOrdersSelector,
  getUser
} from '../../../modules/selectors';
import { find, pickBy, omit } from 'lodash';
import moment from 'moment';

export const fetchProductPricingListSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Shop.getProductPricingLists);
    ApiErrors.checkOnApiError(response);
    const defaultItem = find(response, { default: true }) || response[0];
    if (defaultItem) {
      yield put(setSelectedPriceListAction(defaultItem._id));
    }
    yield put(fetchProductPricingListSuccessAction(response));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const createProductPriceListSaga = function* ({
  payload
}: ISagaAction<ICreateProductPriceListParams>): SagaIterator {
  try {
    const pricesList = yield select(getProductPrisingListSelector);
    const response = yield call(Api.Shop.createProductPricingList, payload);
    ApiErrors.checkOnApiError(response);
    pricesList.push(response);
    yield put(fetchProductPricingListSuccessAction(pricesList));
    yield put(setSelectedPriceListAction(response._id));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const deleteProductPriceListSaga = function* ({
  payload
}: ISagaAction<IDeleteProductPriceListParams>): SagaIterator {
  try {
    const pricesList = yield select(getProductPrisingListSelector);
    const response = yield call(Api.Shop.deleteProductPricingList, payload);
    ApiErrors.checkOnApiError(response);

    yield put(
      fetchProductPricingListSuccessAction(
        pricesList.filter((pricesList: any) => pricesList._id !== payload.id)
      )
    );

    const anotherPricingList = pricesList.find((pricesList: any) => pricesList._id !== payload.id);
    if (anotherPricingList) yield put(setSelectedPriceListAction(anotherPricingList._id));

    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const fetchUserPricingSaga = function* (): SagaIterator {
  try {
    const selectedPrisingList = yield select(getSelectedPriceListSelector);
    const response = yield call(Api.Shop.fetchProductUserPricing, {
      productPricingList_id: selectedPrisingList
    });
    ApiErrors.checkOnApiError(response);
    yield put(fetchUserPricingSuccessAction(response));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const updatePriceValueSaga = function* ({
  payload
}: ISagaAction<IUpdatePriceValue>): SagaIterator {
  const prices = yield select(getProductUserPricing);
  try {
    const updatedPrices = prices.map((price: IPriceItem) =>
      price._id === payload.id
        ? {
            ...price,
            [payload.data.key]: payload.data.value
          }
        : price
    );
    yield put(fetchUserPricingSuccessAction([...updatedPrices]));
    yield delay(1000);
    const response = yield call(Api.Shop.updateProductUserPricing, payload);
    ApiErrors.checkOnApiError(response);
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const fetchUserAccountBalanceSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Shop.getUserAccountBalance);
    ApiErrors.checkOnApiError(response);
    yield put(fetchUserAccountBalanceSuccessAction(response));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const fetchCouponsListSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Shop.fetchCouponsList);
    ApiErrors.checkOnApiError(response);
    yield put(fetchCouponsSuccessAction(response.result.list));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

const getCouponData = function* (couponData: IShopCoupon): SagaIterator<IShopCoupon> {
  const user = yield select(getUser);
  const { code, name, validUntilDate, discounts, minimumOrderValue } = couponData;
  const cleanedDiscounts = discounts.map((discount) =>
    pickBy(discount, (value) => ['', 0, null].indexOf(value as any) === -1)
  );
  const validDate = moment(validUntilDate);
  return {
    user: user._id,
    name,
    active: true,
    code,
    minimumOrderValue,
    validUntilDate: validDate.isValid() ? moment(validUntilDate).format() : '',
    // @ts-ignore
    discounts: cleanedDiscounts
  };
};
export const createCouponSaga = function* ({ payload }: ISagaAction<IShopCoupon>): SagaIterator {
  try {
    const couponsList = yield select(getShopCouponsSelector);
    const data = yield call(getCouponData, payload);
    const response = yield call(Api.Shop.createCoupon, data);
    ApiErrors.checkOnApiError(response);
    couponsList.push(response.result);
    yield put(fetchCouponsSuccessAction([...couponsList]));
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const deleteCouponSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  try {
    const response = yield call(Api.Shop.deleteCoupon, payload);
    ApiErrors.checkOnApiError(response);
    const couponsList = yield select(getShopCouponsSelector);
    const filteredList = couponsList.filter((coupon: IShopCoupon) => coupon.id !== payload);
    yield put(fetchCouponsSuccessAction(filteredList));
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const updateCouponSaga = function* ({ payload }: ISagaAction<IShopCoupon>): SagaIterator {
  try {
    const data = yield call(getCouponData, payload);
    const response = yield call(Api.Shop.updateCoupon, omit(data, 'user'), payload.id || '');
    ApiErrors.checkOnApiError(response);
    const couponsList = yield select(getShopCouponsSelector);
    const updatedList = couponsList.map((coupon: IShopCoupon) =>
      coupon.id === payload.id ? payload : coupon
    );
    yield put(fetchCouponsSuccessAction(updatedList));
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const fetchProductGroupsSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Shop.getProductGroups);
    ApiErrors.checkOnApiError(response);
    yield put(fetchProductGroupsSuccessAction(response.result.list));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const fetchProductsSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Shop.getProducts);
    ApiErrors.checkOnApiError(response);
    yield put(fetchProductsSuccessAction(response.result.list));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const fetchGlobalShopSettingsSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Shop.getGlobalShopSettingsForCurrentUser);
    ApiErrors.checkOnApiError(response);
    yield put(getGlobalShopSettingsSuccess(response.result));
  } catch (e) {
    if ((e as any).code === 404) {
      yield put(getGlobalShopSettingsSuccess(null));
      return;
    }

    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const createGlobalShopSettingsSaga = function* ({
  payload
}: ISagaAction<ICreateGlobalShopSettingsParams>): SagaIterator {
  try {
    const response = yield call(Api.Shop.createGlobalShopSettings, payload);
    ApiErrors.checkOnApiError(response);
    yield put(getGlobalShopSettingsSuccess(response.result));
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const updateGlobalShopSettingsSaga = function* ({
  payload
}: ISagaAction<IUpdateGlobalShopSettingsParams>): SagaIterator {
  try {
    const response = yield call(Api.Shop.updateGlobalShopSettings, omit(payload, 'id'), payload.id);
    ApiErrors.checkOnApiError(response);
    yield put(getGlobalShopSettingsSuccess(response.result));
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const fetchOrderListForUserSaga = function* ({
  payload
}: ISagaAction<IFetchOrderListForUserParams>): SagaIterator {
  try {
    const response = yield call(Api.Shop.fetchOrdersForUser, payload);
    ApiErrors.checkOnApiError(response);
    yield put(fetchOrderListForUserSuccessAction(response.result));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const cancelOrderSaga = function* ({
  payload
}: ISagaAction<ICancelOrderParams>): SagaIterator {
  try {
    const response = yield call(Api.Shop.cancelOrder, payload);
    ApiErrors.checkOnApiError(response);
    const orders = yield select(getShopOrdersSelector);
    yield put(
      fetchOrderListForUserSuccessAction({
        ...orders,
        list: orders.list.map((order: IShopOrder) => {
          if (order.id !== response.result.id) return order;
          return response.result;
        })
      })
    );
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const fetchOrderImagesActionSaga = function* ({
  payload
}: ISagaAction<string>): SagaIterator {
  try {
    const response = yield call(Api.Shop.fetchOrderImagesForUser, payload);
    ApiErrors.checkOnApiError(response);
    yield put(fetchOrderImagesSuccessAction(response.result));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const fetchLaboratoriesSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Shop.fetchLaboratories);
    ApiErrors.checkOnApiError(response);
    yield put(fetchLaboratoriesSuccessAction(response.result.list));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const salesAutomationBulkUpdateSaga = function* ({
  payload
}: ISagaAction<ISalesConfigBulkUpdateAction>): SagaIterator {
  try {
    yield put(setSalesAutomationAction({ bulkUpdateInProgress: true }));
    const response = yield call(Api.Shop.salesAutomationBulkUpdate, payload);
    yield put(setSalesAutomationAction({ bulkUpdateInProgress: false }));
    ApiErrors.checkOnApiError(response);
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(setSalesAutomationAction({ bulkUpdateInProgress: false }));
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const fetchPayDocuments = function* (): SagaIterator {
  try {
    const { result } = yield call(Api.Shop.fetchPayDocumnets);
    yield put(setPayDocumentsSuccessAction(result.list));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const userHasEnoughInvoicingData = function* (): SagaIterator {
  try {
    const { result } = yield call(Api.Shop.userHasEnoughInvoicingData);
    yield put(userHasEnoughInvoicingDataSuccessAction(result.userHasEnoughInvoicingData));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};
