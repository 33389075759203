export const TAX_QUICK_LIST = [
  {
    value: '19',
    textKey: 'germanyName'
  },
  {
    value: '20',
    textKey: 'austriaName'
  },
  {
    value: '7.7',
    textKey: 'switzName'
  }
];

export const SETTINGS_TABS = [
  {
    id: 'watermark-and-brand',
    label: 'watermark.watermark-and-brand'
  },
  {
    id: 'gallery-presets',
    label: 'galleryPresets.route'
  },
  {
    id: 'tax',
    label: 'settingsSection.taxSettingMenuLabel'
  },
  {
    id: 'messages',
    label: 'messages.route'
  },
  {
    id: 'domains',
    label: 'customDomain'
  },
  {
    id: 'language',
    label: 'languageSettingsMenu'
  }
];
