import { IStoreState } from '../types';
import { createSelector } from 'reselect';
import { orderBy, find, get } from 'lodash';

export const getGalleriesList = ( state: IStoreState ) => state.galleries.items;
export const getGalleriesLength = createSelector( getGalleriesList, galleries => galleries.length );
export const getIsGalleryOnCreation = ( state: IStoreState ) => state.galleries.isOnCreation;
export const getIsGalleriesFetched = ( state: IStoreState ) => state.galleries.isFetched;
export const getCurrentGalleryId = (state: IStoreState) => state.galleries.currentGalleryId;

export const getOrderedGalleries = createSelector( getGalleriesList, galleries => orderBy( galleries, 'order' ) );

export const getGalleryById = createSelector( getGalleriesList,
	( galleries ) => ( _id: string ) => find( galleries, { _id } ) || {} );

export const getImagesToMoveOnCreate = ( state: IStoreState ) => state.galleries.imageIDsMoveOnCreate;

export const isGalleriesHaveUploading = ( state: IStoreState ) => find( state.galleries.items, gallery => get( gallery, 'uploadingProcess.start', false ) || get( gallery, 'videoUploadingProcess.isUploading', false ) );
export const getCollectionsViewMode = (state: IStoreState) => state.galleries.viewMode;
