import React, { useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import useTrackPageView from '../../hooks/tracking/use-track-page-view';
import { useIsPaymentProblem } from '../../common/use-is-payment-problem';
import qs from 'querystring';

interface IPaymentProblems {
  [key: string]: Array<string>;
}

const allowedRoutesMap: IPaymentProblems = {
  paymentproblem: ['/subscription', '/subscription/pricing/payment'],
  'chargeback-fee-unpaid': ['/subscription']
};

const SubscriptionRoute = (props: RouteProps & { pageId: string }) => {
  const history = useHistory();
  const { isPaymentProblem, state } = useIsPaymentProblem();
  const {
    location: { pathname, search }
  } = history;
  const searchParams = qs.parse(search.substring(1));

  const trackPageView = useTrackPageView();
  useEffect(() => {
    trackPageView(props.pageId, props.path as string);
  }, []); // eslint-disable-line

  if (
    (state === 'payment_setup_problem' || searchParams.setup_failed) &&
    pathname !== '/subscription/pricing/payment'
  ) {
    let queryString = '';
    if (searchParams.setup_failed) {
      queryString = search;
    }

    history.push('/subscription/pricing/payment' + queryString);

    return null;
  }

  const allowedRoutes = allowedRoutesMap[state] || [];
  const notAllowedRoutes = !allowedRoutes.includes(pathname);

  if (isPaymentProblem && notAllowedRoutes) {
    history.push('/subscription');

    return null;
  }

  return <Route {...props} />;
};

export default SubscriptionRoute;
