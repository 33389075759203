import React from 'react';
import Texts from '../../json/texts.json';
import moment from 'moment';
import { connect } from 'react-redux';

const DefaultFooter = ({ whiteLabel }) =>
  whiteLabel ? (
    <span>
      <a target="_blank" href={whiteLabel.companyUrl} rel="noopener noreferrer">
        {whiteLabel.companyName}
      </a>{' '}
      &copy; {moment().year()}{' '}
      <a target="_blank" href={whiteLabel.imprintUrl} rel="noopener noreferrer">
        {Texts[window.LANGUAGE_SW].imprint}
      </a>
      {' | '}
      <a target="_blank" href={whiteLabel.privacyUrl} rel="noopener noreferrer">
        {Texts[window.LANGUAGE_SW].privacy}
      </a>
      {whiteLabel && whiteLabel.type === 'SB' && (
        <span>
          {' | '}
          <a target="_blank" href="https://scrappbook.statuspage.io" rel="noopener noreferrer">
            Server Status
          </a>
        </span>
      )}
    </span>
  ) : null;

const mapStateToProps = ({ auth: { whiteLabel } }) => ({
  whiteLabel
});

export default connect(mapStateToProps, null)(DefaultFooter);
