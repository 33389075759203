import React from 'react';
import { IFavorite } from '../../modules/types/selections';
import { FaHeart, FaComment } from 'react-icons/fa';
import styled from 'styled-components';
import { get } from 'lodash';
import { UncontrolledTooltip } from 'reactstrap';

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 2px;
  z-index: 3;
  svg {
    position: initial;
    margin-left: 5px;
  }
`;

interface IProps {
  favorite?: IFavorite;
}

export const ImageCardFavoritesIcons = ({ favorite }: IProps) => {
  const like = get(favorite, 'like', false);
  const comment = get(favorite, 'comment', false);

  return favorite ? (
    <Wrapper>
      {like ? <FaHeart color="#f64846" size={20} /> : null}
      {comment ? (
        <FaComment color="#7b7b7b" size={20} id={`favorite_comment_${favorite._id}`} />
      ) : null}
      {favorite.comment ? (
        <UncontrolledTooltip
          autohide={false}
          placement="top"
          target={`favorite_comment_${favorite._id}`}
        >
          {favorite.comment}
        </UncontrolledTooltip>
      ) : null}
    </Wrapper>
  ) : null;
};
