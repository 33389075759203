import { createAction } from 'redux-act';
import { IImage } from '../../modules/reducers/images';
import {
  IDeleteGalleryImagesAction,
  IDragImageAction,
  IDragMultiImageAction,
  IMoveImagesToGallery,
  ISelectSelectionImageAction,
  IToggleImageWatermarkAction,
  IUploadImagesAction,
  ISetGifMetaDataAction,
  ISetFocalPointParams,
  IDeleteHeaderImageAction
} from '../../modules/types';

export const clearImages = createAction();

export const fetchImages = createAction();
export const fetchImagesSuccess = createAction<{ items?: IImage[]; appIcon?: IImage }>();
export const fetchImagesFailed = createAction();

export const searchImage = createAction<string>();

export const setImageTitle = createAction<IImage>();
export const setFocalPointAction = createAction<ISetFocalPointParams>();
export const setSmallScreenImageTitle = createAction<IImage>();
export const openImageInFullscreenAction = createAction<{ images: IImage[]; index: number }>();
export const updateFullscreenImageListAction = createAction<{ images: IImage[] }>();
export const closeFullscreen = createAction();
export const deleteImage = createAction<string>();
export const deleteImages = createAction();
export const deleteGalleryImages = createAction<IDeleteGalleryImagesAction>();

export const selectImage = createAction<string>();
export const selectImages = createAction<string[]>();
export const selectImageSuccess = createAction<string[]>();
export const invertSelectedImages = createAction();
export const clearSelected = createAction();
export const resetImagesSelectionsFilter = createAction<boolean>();

export const moveSelectedImagesToGallery = createAction<string>();
export const moveImagesToGallery = createAction<IMoveImagesToGallery>();

export const actionDropImage = createAction<IDragImageAction>();
export const actionDropMultiImage = createAction<IDragMultiImageAction>();

export const toggleImageWatermark = createAction<IToggleImageWatermarkAction>();

export const setIsCropped = createAction<boolean>();

export const uploadImages = createAction<IUploadImagesAction>();
export const startUploadingProcess = createAction<any>();
export const uploadImage = createAction<IImage>();

export const duplicatesAction = createAction<string>();

export const pauseUploading = createAction<boolean>();
export const restoreUploading = createAction<boolean>();
export const stopUploading = createAction<string>();

export const imageUploadingNext = createAction();

export const updateSingleImageData = createAction<any>();

export const imagesChunkUploadingSuccess = createAction();

export const selectSelectionImagesByFilter = createAction<ISelectSelectionImageAction>();

export const cleanUpImagesSuccess = createAction<{ items?: IImage[]; appIcon?: IImage }>();

export const setIsImageDarggingAction = createAction<any>();

export const setGifMetaData = createAction<ISetGifMetaDataAction>();

export const deleteHeaderImage = createAction<IDeleteHeaderImageAction>();

export const addImagesToUploadSession = createAction<{ albumId: string; images: IImage[] }>();
export const updateImageUploadSessionData =
  createAction<{ albumId: string; image: IImage; stopUploading?: boolean }>();
export const clearImagesUploadSession = createAction();
