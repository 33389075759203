import { IStoreState } from '../types';
import { createSelector } from 'reselect';
import { find, matches, chain } from 'lodash';

export const getTemplatesList = (state: IStoreState) => state.templates.items;
export const getDevelopedTemplates = createSelector([getTemplatesList], (templates) =>
  chain(templates).filter({ inDevelopment: false }).orderBy(['order'], ['asc']).value()
);

export const getSelectedTemplate = createSelector(
  getDevelopedTemplates,
  (templates) =>
    (name: string = '', type: string = '') =>
      find(
        templates,
        matches({
          name,
          type
        })
      ) || {}
);

export const getPaidTemplatesOptions = createSelector(
  [getTemplatesList],
  (templates) => (currencySymbol: string) =>
    chain(templates)
      .filter({ isPurchable: true })
      .map((template) => ({
        text: template.displayName + ` - ${template.price}${currencySymbol}`,
        value: template._id
      }))
      .value()
);

export const getTemplatesFonts = (state: IStoreState) => state.templates.fonts;
