import React, { useEffect, useState } from 'react';
import { IDictionary, IFetchOrderListForUserParams, IPriceItem, IShopCoupon } from '../types';
import { partial, get } from 'lodash';
import { InputItem } from '../../components/Forms/InputItem';
import { PriceInput } from '../../components/PriceInput';
import { displayPrice, getTranslationKey } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { getShopOrdersSelector, getUser } from '../selectors';
import { fetchOrderListForUserAction, updatePriceValueAction } from '../actions';
import { MarginCol } from '../../pages/shopPricing/pricingTable/MarginCol';
import { CouponActionsButton } from '../../pages/shopCoupons/CouponsActionsButton';
import moment from 'moment';
import { OrdersActionsButton } from '../../pages/shopOrders/OrdersActionsButton';
import { displayAmount } from '../utils/helpers/display-price';

interface IUsePrisingTableData {
  tableList: any[];
  tableCols: string[];
  tableColsNames: string[];
  sortable: IDictionary<string>[];
}

const tableColsList = [
  'active',
  'productName',
  'resellerPriceTotal',
  'priceTotal',
  'margin',
  'additionalImagePriceTotal',
  'imagesIncluded',
  'additionalPagePriceTotal',
  'pagesIncluded'
];

const getProductName = (priceItem: IPriceItem) => {
  const {
    _product,
    additionalImageResellerPriceTotal = 0,
    additionalPageResellerPriceTotal = 0,
    currency
  } = priceItem;
  const { dimensions } = _product;
  let additionalPricing = '';
  if (additionalPageResellerPriceTotal > 0) {
    additionalPricing = `(+ ${displayPrice(
      additionalPageResellerPriceTotal,
      currency
    )} ${getTranslationKey('shop.pricingTable.extraPage')})`;
  }
  if (additionalImageResellerPriceTotal > 0) {
    additionalPricing = `(+ ${displayPrice(
      additionalImageResellerPriceTotal,
      currency
    )} ${getTranslationKey('shop.pricingTable.extraImage')})`;
  }

  return `${priceItem.productName} ${dimensions.height}x${dimensions.width}${dimensions.unit} ${additionalPricing}`;
};

const showValue = (value?: number) => (value ? String(displayAmount(value).toFormat('0.00')) : '');

export const usePricingTableData = (prices: IPriceItem[]): IUsePrisingTableData => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const platformPercent = get(user, 'taxSettings.platformPercent', 19);
  const userServiceFeePercent = get(user, 'serviceFee.percent', 7.9) / 100;
  const onChangeCheckbox = (price: any, e: React.SyntheticEvent) => {
    const { checked } = e.target as HTMLFormElement;

    dispatch(
      updatePriceValueAction({
        id: price._id,
        data: {
          key: 'active',
          value: checked
        }
      })
    );
  };
  const callbackPlaceholder = (): null => null;
  const items = prices.map((price) => {
    const serviceFee = Math.round(
      price.priceTotal * userServiceFeePercent * (platformPercent / 100 + 1)
    );
    const margin = price.priceTotal - price.resellerPriceTotal - serviceFee;

    return {
      productName: getProductName(price),
      active: (
        <input
          type="checkbox"
          aria-checked
          placeholder=""
          checked={price.active}
          onChange={partial(onChangeCheckbox, price)}
        />
      ),
      resellerPriceTotal: (
        <InputItem
          changeCallback={callbackPlaceholder}
          value={showValue(price.resellerPriceTotal)}
          disabled
          placeholder=""
        />
      ),
      priceTotal: (
        <PriceInput name="priceTotal" value={showValue(price.priceTotal)} price={price} />
      ),
      margin: showValue(margin),
      additionalImagePriceTotal: (
        <PriceInput
          name="additionalImagePriceTotal"
          value={showValue(price.additionalImagePriceTotal)}
          disabled={!price.allowEditAdditionalImage}
          price={price}
        />
      ),
      imagesIncluded: (
        <PriceInput
          name="imagesIncluded"
          value={price.imagesIncluded}
          disabled={!price.allowEditAdditionalImage}
          price={price}
        />
      ),
      additionalPagePriceTotal: (
        <PriceInput
          name="additionalPagePriceTotal"
          value={showValue(price.additionalPagePriceTotal)}
          disabled={!price.allowEditAdditionalPage}
          price={price}
        />
      ),
      pagesIncluded: (
        <PriceInput
          name="additionalPagePriceTotal"
          value={price.additionalPagePriceTotal}
          disabled={!price.allowEditAdditionalPage}
          price={price}
        />
      )
    };
  });

  return {
    tableList: items,
    tableCols: tableColsList,
    tableColsNames: [
      getTranslationKey('shop.pricingTable.activeLabel'),
      getTranslationKey('shop.pricingTable.productLabel'),
      getTranslationKey('shop.pricingTable.purchaseLabel', { taxPercent: platformPercent }),
      getTranslationKey('shop.pricingTable.sellingLabel'),
      <MarginCol key={8} />,
      getTranslationKey('shop.pricingTable.exPrintLabel'),
      getTranslationKey('shop.pricingTable.printInclLabel'),
      getTranslationKey('shop.pricingTable.exPageLabel'),
      getTranslationKey('shop.pricingTable.pageInclLabel')
    ],
    sortable: []
  };
};

interface ICouponsTableData {
  tableList: any[];
  tableCols: string[];
  tableColsNames: string[];
  sortable: IDictionary<string>[];
  editID: string;
  cleanEditID: () => void;
}

type FetchOrdersProps = {
  skip: number | null;
  limit: number;
  startDate: Date | null;
  endDate: Date | null;
};
interface IOrdersTableData {
  tableList: any[];
  tableCols: string[];
  tableColsNames: string[];
  pagesAmount: number;
  currentPage: number;
  limit: number;
  skip: number | null;
  startDate: Date | null;
  endDate: Date | null;
  showImagesModal: boolean;
  imagesModalOrderId: string;
  showCancelOrderModal: boolean;
  cancelOrderModalOrderId: string;
  onImagesModalClose: () => void;
  onCancelOrderModalClose: () => void;
  setStartDate: (date: null | Date) => void;
  setEndDate: (date: null | Date) => void;
  setSkip: (date: null | number) => void;
  setLimit: (date: number) => void;
  setPage: (page: number) => void;
  fetchOrders: (props: FetchOrdersProps) => void;
}

export const useShopCouponsTableData = (coupons: IShopCoupon[]): ICouponsTableData => {
  const [editID, setItemID] = useState('');

  const cleanEditID = () => {
    setItemID('');
  };

  const items = coupons.map((coupon) => ({
    code: coupon.code,
    name: coupon.name,
    action: (
      <CouponActionsButton
        couponID={coupon.id}
        actionHandler={setItemID}
        isUsedInSalesAutomation={coupon.isUsedInSalesAutomation}
      />
    )
  }));

  return {
    tableList: items,
    tableCols: ['code', 'name', 'action'],
    tableColsNames: [
      getTranslationKey('shop.coupons.codeTableTitle'),
      getTranslationKey('shop.coupons.nameTableTitle'),
      getTranslationKey('shop.coupons.actionTableTitle')
    ],
    sortable: [
      {
        column: 'name',
        sortFunction: 'CaseInsensitive'
      },
      {
        column: 'isDefault',
        sortFunction: 'NumericInteger'
      }
    ],
    editID,
    cleanEditID
  };
};

export const useShopOrdersTableData = (): IOrdersTableData => {
  const dispatch = useDispatch();
  const orders = useSelector(getShopOrdersSelector);
  const startDateInitValue = moment().subtract(30, 'days').toDate();
  startDateInitValue.setHours(0, 0, 0, 0);
  const [startDate, setStartDate] = useState<null | Date>(startDateInitValue);
  const endDateInitValue = new Date();
  endDateInitValue.setHours(23, 59, 59, 999);
  const [endDate, setEndDate] = useState<null | Date>(endDateInitValue);
  const [skip, setSkip] = useState<null | number>(null);
  const [limit, setLimit] = useState<number>(10);
  const [showImagesModal, setShowImagesModal] = useState<boolean>(false);
  const [imagesModalOrderId, setImagesModalOrderId] = useState<string>('');
  const [showCancelOrderModal, setCancelOrderModal] = useState<boolean>(false);
  const [cancelOrderModalOrderId, setCancelOrderModalOrderId] = useState<string>('');

  const setPage = (page: number) => setSkip(page * limit);
  const onImagesModalClose = () => {
    setShowImagesModal(false);
    setImagesModalOrderId('');
  };

  const onCancelOrderModalClose = () => {
    setCancelOrderModal(false);
    setCancelOrderModalOrderId('');
  };

  const fetchOrders = ({ skip, limit, startDate, endDate }: FetchOrdersProps) => {
    const params = {} as IFetchOrderListForUserParams;

    params.limit = limit;

    if (skip !== null) params.skip = skip;
    if (startDate !== null) {
      params.filters = {
        ...params.filters,
        dateRange: {
          ...params?.filters?.dateRange,
          start: startDate.toISOString()
        }
      };
    }

    if (endDate !== null) {
      params.filters = {
        ...params.filters,
        dateRange: {
          ...params?.filters?.dateRange,
          end: endDate.toISOString()
        }
      };
    }

    dispatch(fetchOrderListForUserAction(params));
  };

  useEffect(() => {
    fetchOrders({
      skip,
      limit,
      startDate,
      endDate
    });
  }, [skip, limit]); // eslint-disable-line

  // @ts-ignore
  const items = orders.list.map((order) => ({
    ...order,
    orderNumber: order.orderNumber,
    date: order.createdAt,
    gallery: order.collectionTitle,
    invoiceNumber: order.invoiceNumbers,
    customer: {
      ...order.shipping,
      email: order.endCustomerInfo.email,
      phone: order.endCustomerInfo.phone
    },
    sales: order.grandTotal,
    earnings: order.userEarnings,
    status: order.status,
    action: (
      <OrdersActionsButton
        orderId={order.id}
        invoiceDownloadLink={order.invoiceDownloadLink}
        openImagesModal={(orderId) => {
          setImagesModalOrderId(orderId);
          setShowImagesModal(true);
        }}
        openCancelOrderModal={(orderId) => {
          setCancelOrderModalOrderId(orderId);
          setCancelOrderModal(true);
        }}
      />
    )
  }));

  const pagesAmount = Math.ceil(orders.meta.totalAmount / limit);
  const currentPage = skip ? Math.floor(skip / limit) : 0;

  return {
    tableList: items,
    tableCols: [
      'date',
      'gallery',
      'orderNumber',
      'invoiceNumber',
      'customer',
      'sales',
      'earnings',
      'status',
      'action'
    ],
    tableColsNames: [
      getTranslationKey('shop.orders.dateTableTitle'),
      getTranslationKey('shop.orders.galleryTableTitle'),
      getTranslationKey('shop.orders.orderNumberTableTitle'),
      getTranslationKey('shop.orders.invoiceNumberTableTitle'),
      getTranslationKey('shop.orders.customerTableTitle'),
      getTranslationKey('shop.orders.salesTableTitle'),
      getTranslationKey('shop.orders.earningsTableTitle'),
      getTranslationKey('shop.orders.statusTableTitle'),
      getTranslationKey('shop.orders.actionTableTitle')
    ],
    setStartDate: (date) => {
      if (date) {
        date.setHours(0, 0, 0, 0);
      }

      setStartDate(date);
    },
    setEndDate: (date) => {
      if (date) {
        date.setHours(23, 59, 59, 999);
      }

      setEndDate(date);
    },
    setSkip,
    setLimit,
    setPage,
    pagesAmount,
    currentPage,
    limit,
    skip,
    startDate,
    endDate,
    fetchOrders,
    showImagesModal,
    imagesModalOrderId,
    onImagesModalClose,
    showCancelOrderModal,
    cancelOrderModalOrderId,
    onCancelOrderModalClose
  };
};
