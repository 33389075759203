import React, { useEffect, useState } from 'react';
import { IDictionary, IPriceItem } from '../../modules/types';
import { useDispatch } from 'react-redux';
import { updatePriceValueAction } from '../../modules/actions';
import { InputItem } from '../Forms';
import styled from 'styled-components';
import { displayAmount } from '../../modules/utils/helpers/display-price';

const Wrapper = styled.div`
  input {
    &:required,
    &:invalid {
      box-shadow: none;
    }
  }
`;

const getValidValue = (name: string, value: number, price: IPriceItem) => {
  if (name === 'priceTotal') {
    const min = displayAmount(price.resellerPriceTotal).toUnit();

    return value < min ? min : parseFloat(value.toFixed(2));
  }
  if (name === 'additionalImagePriceTotal' || name === 'additionalPagePriceTotal') {
    return parseFloat(value.toFixed(2));
  }
  if (name === 'imagesIncluded' || name === 'pagesIncluded') {
    return Math.round(value);
  }

  return value;
};

const getMin = (name: string, price: IPriceItem) => {
  const mins: IDictionary<number> = {
    priceTotal: displayAmount(price.resellerPriceTotal).toUnit(),
    default: 0
  };

  return mins[name] || mins.default;
};

interface IProps {
  name: string;
  value?: number | string;
  disabled?: boolean;
  price: IPriceItem;
}

export const PriceInput = ({ name, value = '', disabled = false, price }: IProps) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(value as any);
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const updateInputValue = () => {
    const validated = getValidValue(name, parseFloat(inputValue), price);
    const isFormatted = ['imagesIncluded', 'pagesIncluded'].indexOf(name) === -1;
    dispatch(
      updatePriceValueAction({
        id: price._id,
        data: {
          key: name,
          value: isFormatted
            ? parseFloat(String((validated * 100).toFixed()))
            : parseFloat(String(validated))
        }
      })
    );
  };
  const step = ['imagesIncluded', 'pagesIncluded'].indexOf(name) > -1 ? '1' : '0.1';
  const changeCallback = (changedValue: string) => setInputValue(changedValue);

  return (
    <Wrapper>
      <InputItem
        type="number"
        step={step}
        changeCallback={changeCallback}
        onBlurCallback={updateInputValue}
        value={inputValue}
        placeholder="-"
        disabled={disabled}
        min={getMin(name, price)}
      />
    </Wrapper>
  );
};
