import {
  changeModalState,
  fetchPaymentInfoAction,
  fetchPricingAction,
  fetchTemplates
} from '../../modules/actions';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { getPaymentInfo } from '../../modules/selectors/paymentInfo';
import { InputItem, SelectItem } from '../../components';
import { Api, getTranslationKey } from '../../modules/utils';
import { VIDEO_ADDON_OPTIONS } from '../../modules/constants';
import { useSubscriptionCurrency } from '../../modules/hooks/useSubscriptionCurrency';
import { getPaidTemplatesOptions, getTemplatesList } from '../../modules/selectors/templates';
import { get } from 'lodash';
import { getModalData, getModalState, getUser } from '../../modules/selectors';
import { IUpdateAddonsParams } from '../../modules/types/subscription';
import { ISubscription } from '../../modules/types/paymentInfo';
import { formatPrice } from './helpers/format-price';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { StripeElementLocale, loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const keyMap = {
  core: 'core',
  slideshow: 'slideshows',
  brand: 'brands',
  domain: 'domains',
  video: 'video-storage'
} as const;

const getVideoOption = (billingCycle: string, currencySymbol: string) =>
  VIDEO_ADDON_OPTIONS[billingCycle].map((option) => ({
    ...option,
    text: option.text.replace('{currencySymbol}', currencySymbol)
  }));

const UpdateAddonsModalUnwrapped = () => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const isOpen = useSelector(getModalState)('updateAddonsModal');
  const { addon, templateId } = useSelector(getModalData) as {
    addon: 'slideshows' | 'video-storage' | 'brands' | 'domains' | 'template';
    templateId: string;
  };
  const { currencySymbol, subscriptionCurrency } = useSubscriptionCurrency();
  const user = useSelector(getUser);
  const { pricing, subscriptions, oneTimePurchases } = useSelector(getPaymentInfo);
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [quantityValue, setQuantityValue] = useState('1');
  const quantity = Number.isNaN(parseInt(quantityValue)) ? null : parseInt(quantityValue);
  const [videoPlan, setVideoPlan] = useState('');
  const [loading, setLoading] = useState(false);
  const [watchPaymentInfo, setWatchPaymentInfo] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const toggle = () =>
    dispatch(
      changeModalState({ key: 'updateAddonsModal', state: !isOpen, modalData: { addon: null } })
    );
  const handleError = (error: any) => {
    setLoading(false);
    setWatchPaymentInfo(false);
    setErrorMessage(error.message);
  };

  const videoStorageOptions = getVideoOption(billingCycle, currencySymbol);
  const purchasedTemplates = get(user, 'subscription.purchased_templates', []);
  const discountTemplate: string =
    (billingCycle === 'yearly' ||
      get(user, 'digistore.discountKey.free_template_for_monthly_subscription', false)) &&
    ((get(user, 'digistore.discountKey.free_template_only_for_professional', false) &&
      user.subscription.plan === 'professional') ||
      !get(user, 'digistore.discountKey.free_template_only_for_professional', false))
      ? get(user, 'digistore.discountKey.free_template', '')
      : '';
  const templates = useSelector(getTemplatesList);
  const templatesOptions = useSelector(getPaidTemplatesOptions)(currencySymbol)
    .filter((option) => !purchasedTemplates.includes(option.value.split('_').pop()))
    .filter((option) => (discountTemplate ? option.value !== discountTemplate : true))
    .map((option) => ({ text: option.text, value: option.value }));

  let totalGross = 0;
  let totalNet = 0;
  const returnUrlParams = {
    coupon_code: '',
    currency: subscriptionCurrency,
    product_name: '',
    product_id: '',
    quantity,
    isTest:
      process.env.NODE_ENV !== 'production' ||
      user.builderDomain.includes('builder-dev') ||
      user.builderDomain.includes('builder-staging')
  };

  if (addon === 'template') {
    const template = pricing?.templates?.find(({ templateId }) => templateId === selectedTemplate);
    totalNet = (template?.priceCents ?? 0) / 100;

    returnUrlParams.product_name = template?.displayName;
    returnUrlParams.product_id = template?.productStripeId;
  }
  if (addon === 'video-storage') {
    const video = get(pricing, `addonPricing.video-storage.${videoPlan}`, {}) as any;
    totalNet = get(video, `${billingCycle}PriceCents`, 0) / 100;

    returnUrlParams.product_name = video.displayName;
    returnUrlParams.product_id = video.productStripeId;
  }
  if (addon === 'slideshows') {
    const slideshow = get(pricing, 'addonPricing.slideshows.unlimited', {}) as any;
    totalNet = get(slideshow, `${billingCycle}PriceCents`, 0) / 100;

    returnUrlParams.product_name = slideshow.displayName;
    returnUrlParams.product_id = slideshow.productStripeId;
  }
  if (addon === 'brands' || addon === 'domains') {
    const brandsOrDomains = get(pricing, `addonPricing.${addon}`, {}) as any;
    totalNet = (get(brandsOrDomains, `${billingCycle}PriceCents`, 0) * quantity) / 100;

    returnUrlParams.product_name = brandsOrDomains.displayName;
    returnUrlParams.product_id = brandsOrDomains.productStripeId;
  }

  totalGross = Math.floor(totalNet * 100 * (1 + user.taxSettings.platformPercent / 100)) / 100;

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchPaymentInfoAction());
      dispatch(fetchPricingAction());
      dispatch(fetchTemplates());
    }
  }, [isOpen]); // eslint-disable-line

  useEffect(() => {
    setSelectedTemplate(templateId);
  }, [templateId]);

  const subscriptionRef = useRef(subscriptions);

  useEffect(() => {
    // Once user confirmed changes to subscription we need to wait for invoice to get through
    // And then either show an error or redirect to thanks page
    let timeoutId: any;
    if (watchPaymentInfo) {
      const findAddonSub = (subscription: ISubscription) =>
        subscription.subscriptionType &&
        keyMap[subscription.subscriptionType as keyof typeof keyMap] === addon;
      const addonSub = subscriptions?.find(findAddonSub);
      const prevAddonSub = subscriptionRef.current?.find(findAddonSub);
      const addonSubChanged =
        addonSub &&
        (addonSub?.lastInvoiceId !== prevAddonSub?.lastInvoiceId ||
          addonSub?.isCanceled !== prevAddonSub.isCanceled);

      const coreSub = subscriptions?.find(
        (subscription) => subscription.subscriptionType === 'core'
      );
      const prevCoreSub = subscriptionRef.current?.find(
        (subscription) => subscription.subscriptionType === 'core'
      );
      const coreSubChanged =
        coreSub && prevCoreSub && coreSub.lastInvoiceId !== prevCoreSub.lastInvoiceId;

      const templatePurchase = oneTimePurchases?.find(
        (purchase) => purchase.productId === returnUrlParams.product_id
      );

      if (addonSubChanged || coreSubChanged || templatePurchase) {
        const orderId = addonSubChanged
          ? addonSub.lastInvoiceId
          : coreSubChanged
          ? coreSub.lastInvoiceId
          : templatePurchase?.invoiceId;
        setLoading(false);
        setWatchPaymentInfo(false);
        toggle();
        if (!(coreSub?.isPaymentProblem || addonSub?.isPaymentProblem)) {
          window.location.href = `https://${user.domain}/thanks?${queryString.stringify({
            ...returnUrlParams,
            order_id: orderId,
            order_item_id: orderId
          })}`;
        }
      } else {
        timeoutId = setTimeout(() => {
          dispatch(fetchPaymentInfoAction());
        }, 5000);
      }
    }

    subscriptionRef.current = subscriptions;

    return () => {
      clearTimeout(timeoutId);
    };
  }, [loading, watchPaymentInfo, subscriptions]);

  const [confirmSubscriptionChangeParams, setConfirmSubscriptionChangeParams] = useState({
    clientSecret: '',
    invoiceId: '',
    paymentMethodId: ''
  });
  async function updateAddons() {
    setLoading(true);
    const subscriptionConfig: IUpdateAddonsParams['subscriptionConfig'] = {};

    if (addon === 'template') {
      const template = pricing.templates.find(({ templateId }) => templateId === selectedTemplate);
      subscriptionConfig.templates = [
        {
          priceId: template.priceStripeId
        }
      ];
    }

    if (addon === 'video-storage') {
      subscriptionConfig['video-storage'] = {
        priceId: get(
          pricing,
          `addonPricing.video-storage.${videoPlan}.${billingCycle}PriceStripeId`
        )
      };
    }

    if (addon === 'slideshows') {
      subscriptionConfig.slideshows = {
        priceId: get(pricing, `addonPricing.slideshows.unlimited.${billingCycle}PriceStripeId`)
      };
    }

    if (addon === 'brands' || addon === 'domains') {
      subscriptionConfig[addon] = {
        quantity,
        priceId: get(pricing, `addonPricing.${addon}.${billingCycle}PriceStripeId`)
      };
    }

    let clientSecret;
    let invoiceId;
    let paymentMethodId;
    if (
      confirmSubscriptionChangeParams.clientSecret &&
      confirmSubscriptionChangeParams.invoiceId &&
      confirmSubscriptionChangeParams.paymentMethodId
    ) {
      clientSecret = confirmSubscriptionChangeParams.clientSecret;
      invoiceId = confirmSubscriptionChangeParams.invoiceId;
      paymentMethodId = confirmSubscriptionChangeParams.paymentMethodId;
    } else {
      const res = await Api.Subscription.updateAddons({
        subscriptionConfig
      });

      if (
        res.result.paymentIntentClientSecret &&
        res.result.invoiceId &&
        res.result.paymentMethodId
      ) {
        clientSecret = res.result.paymentIntentClientSecret;
        invoiceId = res.result.invoiceId;
        paymentMethodId = res.result.paymentMethodId;

        setConfirmSubscriptionChangeParams({
          clientSecret,
          invoiceId,
          paymentMethodId
        });
      } else {
        setWatchPaymentInfo(true);
      }
    }

    if (clientSecret && invoiceId && paymentMethodId) {
      const returnUrl = `https://${user.domain}/thanks?${queryString.stringify({
        ...returnUrlParams,
        order_id: invoiceId,
        order_item_id: invoiceId
      })}`;

      const confirmParams = {
        return_url: `${window.API_URL}/api/subscription/stripe-payment-complete?builder_domain=${
          user.builderDomain
        }&return_url=${encodeURIComponent(returnUrl)}`,
        payment_method: paymentMethodId
      };

      const { error } = await stripe.confirmPayment({
        clientSecret,
        confirmParams
      });

      if (error) {
        handleError(error);
      }
    } else {
      setWatchPaymentInfo(true);
    }
  }

  const priceText = getTranslationKey('priceWithCurrency', {
    price: formatPrice(totalGross),
    currency: currencySymbol
  });
  const buttonLabel = `${getTranslationKey('subscription.updateAddons.pay')} ${priceText} ${
    addon === 'template'
      ? getTranslationKey('subscription.updateAddons.priceText')
      : billingCycle === 'monthly'
      ? getTranslationKey('subscription.updateAddons.perMonth')
      : getTranslationKey('subscription.updateAddons.perYear')
  }`;

  const userAlreadyHasAddon = subscriptions?.find(
    (subscription) => keyMap[subscription.subscriptionType as keyof typeof keyMap] === addon
  );

  return (
    <Modal centered isOpen={isOpen} toggle={toggle} wrapClassName="modal-dark" size="md">
      <ModalHeader toggle={toggle}>
        {getTranslationKey(`subscription.updateAddons.${addon}.title`)}
      </ModalHeader>
      <ModalBody>
        {addon === 'video-storage' && (
          <SelectItem
            label={getTranslationKey('subscription.updateAddons.video-storage.quantityLabel')}
            defaultOptionsText={getTranslationKey('subscription.videoDefaultOption')}
            value={videoPlan}
            changeCallback={(value) => setVideoPlan(value)}
            options={videoStorageOptions}
          />
        )}
        {(addon === 'brands' || addon === 'domains') && (
          <InputItem
            value={quantityValue}
            withTooltip={false}
            label={getTranslationKey(`subscription.updateAddons.${addon}.quantityLabel`)}
            changeCallback={(value) => setQuantityValue(value)}
            pattern={/^[1-9][0-9]*$/gi}
          />
        )}
        {addon !== 'template' && (
          <SelectItem
            label={getTranslationKey('subscription.updateAddons.billingCycleLabel')}
            value={billingCycle}
            changeCallback={(value) => setBillingCycle(value)}
            options={['monthly', 'yearly'].map((value) => ({
              value,
              text: getTranslationKey(`subscription.${value}`)
            }))}
          />
        )}
        {addon === 'template' && templatesOptions && !!templatesOptions.length && (
          <SelectItem
            label={getTranslationKey('subscription.updateAddons.template.chooseTemplateLabel')}
            defaultOptionsText=""
            value={selectedTemplate}
            changeCallback={(value) => setSelectedTemplate(value)}
            options={templatesOptions}
          />
        )}
        {addon === 'template' && selectedTemplate && (
          <Button
            color="primary"
            className="w-100-p mt-3"
            target="_blank"
            href={templates.find((template) => template._id === selectedTemplate)?.landingPage}
            type="submit"
          >
            {getTranslationKey('subscription.updateAddons.template.landingPage')}
          </Button>
        )}
        <Button
          color="primary"
          className="w-100-p mt-3"
          onClick={() => updateAddons()}
          disabled={
            loading ||
            Object.keys(pricing.corePricing).length === 0 ||
            (addon === 'brands' || addon === 'domains' ? !quantity : false) ||
            (addon === 'video-storage' ? !videoPlan : false)
          }
          type="submit"
        >
          {loading ? getTranslationKey('buttonLoading') : buttonLabel}
        </Button>
        {errorMessage && <div className="color-danger">{errorMessage}</div>}
        {addon === 'template' ? (
          <div>{getTranslationKey('subscription.updateAddons.template.tax')}</div>
        ) : (
          <div>{getTranslationKey('subscription.updateAddons.tax')}</div>
        )}
        {userAlreadyHasAddon && (
          <div className="mt-2">
            {getTranslationKey('subscription.updateAddons.existingAddonMessage')}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export const UpdateAddonsModal = () => {
  const auth = useSelector(getUser);

  return (
    <Elements
      stripe={stripePromise}
      options={{
        locale: ['de', 'en'][auth.languageSoftware] as StripeElementLocale
      }}
    >
      <UpdateAddonsModalUnwrapped />
    </Elements>
  );
};

export default UpdateAddonsModal;
