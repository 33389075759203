import { useWindowWidth } from './useWindowWidth';

interface IBreakpoints {
  desktop: number;
  desktop_min: number;
  mobile: number;
}

export const breakpoints: IBreakpoints = {
  desktop: 1000,
  desktop_min: 768,
  mobile: 684
};


export const useBreakpoint = (breakpoint: keyof IBreakpoints | number): boolean => {
  const { width } = useWindowWidth();

  if (typeof breakpoint === 'number') {
    return width > breakpoint;
  }

  const minWidth = breakpoints[breakpoint];

  return width > minWidth;
};
