import { SagaIterator } from 'redux-saga';
import { IUpdateTaxAction } from '../../../modules/types/user';
import {
  ICreatePayoutAccountParams,
  ISagaAction,
  IUpdatePayoutAccountParams
} from '../../../modules/types';
import { call, put, select } from 'redux-saga/effects';
import { Api, getTranslationKey } from '../../../modules/utils';
import ApiErrors from '../../../modules/utils/API/APIErrors';
import {
  errorsGlobalError,
  fetchPayoutAccountSuccessAction,
  getAvailableCurrenciesSuccessAction,
  setRequiredTaxSavedAction,
  showNotifyFail,
  showNotifySuccess
} from '../../../modules/actions';
import { getUser } from '../../../modules/selectors';
import { omit, pickBy } from 'lodash';

const getTaxDataForAPI = (data: IUpdateTaxAction, taxConfirmed: boolean) => {
  const { vatId, taxId, registrationNumber, commercialRegister, smallBusinessOwner, tax, country } =
    data.form;
  const dataForApi = !data.isEdit
    ? {
        currency: data.form.currency,
        taxSettings: {
          smallBusinessOwner,
          endCustomerPercent: tax
        },
        registrationProcess: {
          setTaxDetails: true
        },
        countryId: country
      }
    : {
        currency: data.form.currency,
        taxSettings: {
          ...pickBy(
            {
              smallBusinessOwner,
              endCustomerPercent: tax,
              taxId,
              registrationNumber,
              commercialRegister
            },
            (value: any) => [''].indexOf(value) === -1
          ),
          vatId
        }
      };

  if (taxConfirmed) {
    delete dataForApi.taxSettings.smallBusinessOwner;
    delete dataForApi.taxSettings.endCustomerPercent;
  }

  return dataForApi;
};

export const updateUserTaxSaga = function* ({
  payload
}: ISagaAction<IUpdateTaxAction>): SagaIterator {
  let user;
  let errorMessage = '';
  try {
    user = yield select(getUser);
    const data = getTaxDataForAPI(payload, user.taxSettings.isConfirmed);
    const response = yield call(Api.User.updateUserByID, data, user._id);

    const errorObj = response.response?.data?.error;

    if (response.code === 400 && errorObj?.name === 'BalanceIsNotZero') {
      errorMessage = getTranslationKey('settingsSection.balanceIsNotZero');
      yield put(showNotifyFail({ message: errorMessage }));
    }
    if (response.code === 400 && errorObj?.name === 'VatIdIsNotValid') {
      errorMessage = getTranslationKey('settingsSection.vatValidationError');
      yield put(showNotifyFail({ message: errorMessage }));
    }

    if (errorMessage) {
      yield put({
        type: 'SET_USER_TAX_SETTINGS',
        payload: {
          ...user.taxSettings,
          errorMessage,
          vatIdIsValid: false
        }
      });
    }

    ApiErrors.checkOnApiError(response);
    if (!payload.isEdit) {
      yield put({
        type: 'set_registration_process',
        payload: { key: 'setTaxDetails', value: true }
      });
    }

    const setUsrPayload: any = {
      currency: data.currency
    };
    if (data.countryId) {
      setUsrPayload['_country'] = data.countryId;
    }

    yield put({
      type: 'SET_USER',
      payload: {
        ...setUsrPayload,
        taxSettings: {
          ...response.result.taxSettings,
          isConfirmed: response.result.taxSettings.isConfirmed,
          errorMessage,
          vatIdIsValid: payload.isPaymentPage ? !errorMessage : false
        }
      }
    });

    yield put({
      type: 'SET_USER_TAX_SETTINGS',
      payload: {
        ...response.result.taxSettings,
        isConfirmed: response.result.taxSettings.isConfirmed,
        errorMessage,
        vatIdIsValid: payload.isPaymentPage ? !errorMessage : false
      }
    });

    yield put(setRequiredTaxSavedAction(true));
    yield put(showNotifySuccess({}));
  } catch (e) {
    if (!errorMessage) {
      yield put(showNotifyFail());
    }

    yield put({
      type: 'SET_USER_TAX_SETTINGS',
      payload: {
        ...user.taxSettings,
        vatIdIsValid: false
      }
    });
  }
};

export const fetchPayoutAccountSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.User.fetchPayoutAccount);
    ApiErrors.checkOnApiError(response);
    yield put(fetchPayoutAccountSuccessAction(response.result));
  } catch (e) {
    if ((e as any).code === 404) return;
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const getAvailableCurrenciesSaga = function* ({
  payload
}: ISagaAction<string | undefined>): SagaIterator {
  try {
    const response = yield call(Api.User.getAvailableCurrencies, payload);
    ApiErrors.checkOnApiError(response);
    yield put(getAvailableCurrenciesSuccessAction(response.result.currencies));
  } catch (e) {
    if ((e as any).code === 404) return;
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const createPayoutAccountSaga = function* ({
  payload
}: ISagaAction<ICreatePayoutAccountParams>): SagaIterator {
  try {
    const response = yield call(Api.User.createPayoutAccount, payload);
    ApiErrors.checkOnApiError(response);
    yield put(fetchPayoutAccountSuccessAction(response.result));
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const updatePayoutAccountSaga = function* ({
  payload
}: ISagaAction<IUpdatePayoutAccountParams>): SagaIterator {
  try {
    const response = yield call(Api.User.updatePayoutAccount, omit(payload, 'id'), payload.id);
    ApiErrors.checkOnApiError(response);
    yield put(fetchPayoutAccountSuccessAction(response.result));
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};
