import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import Texts from '../../../json/texts.json';
import { Col, Row } from 'reactstrap';
import SetDefaultBrandSwitch from './SetDefaultBrandSwitch';
import EditBrand from './EditBrand';
import NewBrand from './NewBrand';
import classnames from 'classnames';
import { getCdnImageUrl } from '../../../helpers/globalFunctions';
import Card, { CardBody, CardFooter } from '../../../components/Card';
import { FaPen, FaPlus, FaTrashAlt } from 'react-icons/fa';

class Brands extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLimitExcceeded: true
    };

    this.editBrand = this.editBrand.bind(this);
    this.toggleEditBrand = this.toggleEditBrand.bind(this);
  }

  editBrand(selectedBrandId) {
    this.setState({ editBrandOpen: true, selectedBrandId });
  }

  toggleEditBrand() {
    this.setState((prevState) => ({
      editBrandOpen: !prevState.editBrandOpen,
      selectedBrandId: null
    }));
  }

  render() {
    const {
      userBrands,
      deleteUserBrand,
      notify,
      availableRules: { brandsLeft }
    } = this.props;
    const { editBrandOpen, selectedBrandId } = this.state;
    const disableBrandsCreation = brandsLeft === null; // we need to disable the creation of brands until we know how many more brands the user can create

    return (
      <Row className="pt-branding-brands-overview">
        <EditBrand
          userBrandId={selectedBrandId}
          toggle={this.toggleEditBrand}
          isOpen={editBrandOpen}
        />
        {Object.keys(userBrands).map(
          (key, index) =>
            userBrands[key] &&
            userBrands[key]._id && (
              <Col sm="12" md="12" lg="6" key={index}>
                <Card>
                  <CardBody className="h-200 text-center position-relative border rounded">
                    {userBrands[key].logo ? (
                      <img
                        src={getCdnImageUrl({ image: userBrands[key].logo })}
                        className="img-fluid watermark-preview-card max-h-150"
                        alt=""
                      />
                    ) : (
                      <img
                        src={window.CDN_URL + '/public/nopic.png'}
                        className="img-fluid watermark-preview-card max-h-150"
                        alt=""
                      />
                    )}
                  </CardBody>
                  <CardFooter className="mt-3 d-flex justify-content-between">
                    {userBrands[key].name}
                    <div className="d-flex align-items-center">
                      <SetDefaultBrandSwitch className="m-t--2" userBrand={userBrands[key]} />
                      <FaPen
                        className="m-t-1 m-r-10 font-lg cursor-pointer color-primary help_setupBrand_pencil"
                        onClick={() => this.editBrand(userBrands[key]._id)}
                      />
                      <FaTrashAlt
                        className={classnames('font-lg color-primary', {
                          'cursor-pointer': !userBrands[key].defaultValues,
                          'cursor-not-allowed': userBrands[key].defaultValues
                        })}
                        onClick={() =>
                          userBrands[key].defaultValues
                            ? notify({
                                title: Texts[window.LANGUAGE_SW].error,
                                message: Texts[window.LANGUAGE_SW].userBrand.canNotDeleteStandard,
                                status: 'error',
                                position: 'tc',
                                dismissible: true,
                                dismissAfter: 10000
                              })
                            : deleteUserBrand(userBrands[key]._id, notify)
                        }
                      />
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            )
        )}
        <Col sm="12" md="12" lg="6">
          <NewBrand
            onUserBrandCreated={(_id) => this.editBrand(_id)}
            disableBrandsCreation={disableBrandsCreation}
          >
            <Card
              className={classnames('text-center', {
                'cursor-pointer': !disableBrandsCreation,
                'cursor-not-allowed': disableBrandsCreation
              })}
            >
              <CardBody className="h-200 text-center position-relative border rounded">
                <img
                  className="img-fluid watermark-preview-card max-h-150"
                  src={window.CDN_URL + '/public/add-brand-grey.png'}
                  alt=""
                />
              </CardBody>
              <CardFooter className="mt-3 d-flex justify-content-between">
                {Texts[window.LANGUAGE_SW].userBrand.add}
                <FaPlus
                  className={classnames({
                    'color-primary': !disableBrandsCreation,
                    'color-secondary': disableBrandsCreation
                  })}
                />
              </CardFooter>
            </Card>
          </NewBrand>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps({
  userBrands,
  notify,
  auth: {
    subscription: { plan }
  },
  accessRules: { availableRules }
}) {
  return { userBrands, notify, plan, availableRules };
}

export default connect(mapStateToProps, actions)(Brands);
