import React, { MutableRefObject, useMemo, useRef, memo } from 'react';
import { IImage } from '../../modules/reducers/images';
import { getCollectionImageURL, getTranslationKey } from '../../modules/utils/helpers';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  selectImageForSlideshow,
  setImageTitle,
  setIsImageDarggingAction,
  setSmallScreenImageTitle,
  setGifMetaData,
  changeModalState,
  selectImages,
  showNotifyFail
} from '../../modules/actions';
import { CollectionDnDModule, isMobile } from '../../modules/utils';
import { get, partialRight } from 'lodash';
import { FaStar, FaRegTrashAlt } from 'react-icons/fa';
import { ReactComponent as FaUsersViewfinder } from '../../assets/img/icons/users-viewfinder-solid.svg';
import { ActionButtonsTypes, ImageCardActions } from './ImageCardActions';
import { IFavorite } from '../../modules/types/selections';
import { ImageCardFavoritesIcons } from './ImageCardFavoritesIcons';
import theme from '../../assets/css/theme';
import { LazyLoadImage, LazyLoadImageProps } from 'react-lazy-load-image-component';
import { UncontrolledTooltip } from 'reactstrap';
import {
  getCollectionsNew,
  getGalleryImagesByGalleryIDs,
  getOrderedGalleries,
  getSelectedImagesIDs
} from '../../modules/selectors';
import { useMultipleSlideSelection } from '../../modules/hooks';

import styled from 'styled-components';

const getMultiSelectMargin = (count?: number) => (count && count > 1 ? `-${count / 2}%` : 0);
const Wrapper = styled.div<{ selectedCount?: number; noDrag?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  transform: translate3d(0, 0, 0);
  user-select: none;
  width: 100%;
  height: 150px;
  padding: ${({ noDrag }) => (noDrag ? '0' : '10px 5px 0 5px')};

  .multi-selection {
    margin-left: ${(props) => getMultiSelectMargin(props.selectedCount)};
    &:first-child {
      margin: 0;
    }
  }

  &:hover {
    .image-tools {
      display: block;
    }
  }
  &.hide-image {
    transform: translateX(-9999px);
    display: none;
  }
  &.drop-start-image {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 243, 205, 0.7);
    }
    .image-tools {
      display: none;
    }
  }
`;

const selectedBorder = `2px solid ${theme.commonColors.second}`;
const ImageInner = styled.div<{ isDraggable: boolean; isSelected: boolean }>`
  border: ${(props) => (props.isSelected ? selectedBorder : ' 1px solid #c8ced3')};
  cursor: ${(props) => props.isDraggable && 'move'};
  position: relative;
  transition: left 0.3s linear;
  left: 0;
  width: 100%;
  border-radius: 5px;
  background: #ededed;
`;

const StarImage = styled(FaStar)`
  position: absolute;
  right: 10px;
  top: 5px;
`;
const DeleteTitleIcon = styled(FaRegTrashAlt)`
  position: absolute;
  left: 10px;
  top: 5px;
  cursor: pointer;
  z-index: 2;
`;
const SetFocalPointIcon = styled(FaUsersViewfinder)`
  position: absolute;
  width: 20px;
  height: 20px;
  left: 10px;
  bottom: 5px;
  cursor: pointer;
  z-index: 2;
`;

interface ImageProps extends LazyLoadImageProps {
  onLoad?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

const Image = styled<React.FC<ImageProps>>(LazyLoadImage)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Cover = styled.div<{ isSelected: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: transparent;
`;

const removePlaceHolder = (className: string) => {
  const dndPlaceholder = document.getElementsByClassName(className);
  if (dndPlaceholder[0]) {
    dndPlaceholder[0].remove();
  }
};

const appendMultiSelectContainer = (
  ev: React.DragEvent,
  isSelected: boolean,
  draggedItem: any,
  selectedImagesIDs: string[]
) => {
  const multi = document.getElementById('multi');

  const target = ev.target as HTMLDivElement;
  if (isSelected && multi) {
    const containerLength = selectedImagesIDs.length * parseInt(draggedItem.style.width);
    multi.style.width = containerLength + 'px';
    target.appendChild(multi);
  }
};

const hideMultiSelectContainerBack = () => {
  const multi = document.getElementById('multi');
  const initialMultiPlace = document.getElementById('multi-wrapper');
  if (multi && initialMultiPlace) {
    initialMultiPlace.appendChild(multi);
  }
};
const selectedImageClassName = 'selected-image';

const toggleSelectedImagesInDrag = (display: string) => {
  const selectedImages = document.getElementsByClassName(selectedImageClassName);
  Array.from(selectedImages).forEach((item: any) => {
    item.style.display = display;
  });
};
const placeholderClassName = 'dnd-placeholder';
const imageInDragBodyClassName = 'image-drag-enabled';
const onDragEndHandler = (ev: React.DragEvent, ref: MutableRefObject<any>) => {
  ev.stopPropagation();
  ref.current.classList.remove('drop-start-image');
  ref.current.classList.remove('hide-image');
  removePlaceHolder(placeholderClassName);
  hideMultiSelectContainerBack();
  toggleSelectedImagesInDrag('flex');
  document.body.classList.remove(imageInDragBodyClassName);
};

interface IProps {
  image: IImage;
  noToolbar?: boolean;
  isUploading?: boolean;
  noDrag?: boolean;
  index?: number;
  isTitleImage?: boolean;
  isSmallScreenTitleImage?: boolean;
  favorite?: IFavorite;
  hideActionsButtons?: ActionButtonsTypes[];
  actionID?: string;
  className?: string;
  isInMultiSelection?: boolean;
  onOpenInFullscreen?: Function;
  isSlideShowPage?: boolean;
  images?: IImage[];
  style?: any;
}

export const ImageCard = memo((data: IProps) => {
  if (!data.image) return null;

  const dependents: any[] = [
    data.image._id,
    data.image.focalPoints,
    data.favorite,
    data.className,
    data.image._watermark,
    data.image.identifier,
    data.isUploading,
    data?.isInMultiSelection,
    data.isSlideShowPage,
    data?.noDrag,
    data?.images,
    data?.index,
    data?.style
  ];
  if (!data.isUploading && data.onOpenInFullscreen) {
    dependents.push(data.onOpenInFullscreen);
  }
  const {
    image,
    index,
    onOpenInFullscreen,
    noToolbar,
    isTitleImage,
    isSmallScreenTitleImage,
    noDrag,
    favorite,
    hideActionsButtons,
    actionID,
    className = '',
    isInMultiSelection,
    isSlideShowPage,
    style
  } = useMemo(() => data, dependents); // eslint-disable-line
  const dispatch = useDispatch();

  const dropContainerRef = useRef(null);
  const selectedImagesIDs = useSelector(getSelectedImagesIDs);
  const isSelected = !noToolbar && selectedImagesIDs?.includes(image._id);
  const isGalleryDnDEvent = (target: React.DragEvent | null) =>
    !noToolbar && target && ['image', 'multi'].includes(CollectionDnDModule.getType());
  const { signedWilCardUrl } = useSelector(getCollectionsNew);
  const handleItemSelection = useMultipleSlideSelection();

  const galleries = useSelector(getOrderedGalleries, shallowEqual);
  const galleriesImages = useSelector(getGalleryImagesByGalleryIDs)(
    galleries.map((g) => ({ gallery_id: g._id, sortBy: g.sortBy, sortOrder: g.sortOrder }))
  );

  const selectImageHandler = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (noDrag) return;

    if (!noToolbar) {
      const selectImagesCallback = (finalList: IImage[]) =>
        dispatch(selectImages(finalList.map(({ _id }) => _id)));

      const galleryFirstImageIndex = galleriesImages.findIndex(
        (i) => i.gallery_id === image.gallery_id
      );
      const currentIdx = index + galleriesImages.slice(0, galleryFirstImageIndex).length;

      handleItemSelection(e, currentIdx, galleriesImages, selectImagesCallback);
    }

    if (isSlideShowPage) {
      dispatch(
        selectImageForSlideshow({
          type: 'image',
          imageId: image._id,
          src: getCollectionImageURL(image, 'L', false, signedWilCardUrl)
        })
      );
    }
  };

  const onDropHandler = (ev: React.DragEvent, imageDropID?: string) => {
    if (noDrag) return;

    const drag_id = ev.dataTransfer.getData('drag_id');
    const drop_id = imageDropID || image._id;
    if (isGalleryDnDEvent(ev) && drag_id) {
      CollectionDnDModule.set({
        type: drag_id === 'multi' ? 'multi' : 'image',
        data: { drag_id, drop_id }
      });
    }
    hideMultiSelectContainerBack();
    toggleSelectedImagesInDrag('flex');
    document.body.classList.remove(imageInDragBodyClassName);
  };

  const onDragEnterHandler = () => {
    if (noDrag) return;

    const classes = get(dropContainerRef, 'current.classList', []);
    removePlaceHolder(placeholderClassName);
    const { drag_id } = CollectionDnDModule.getData();

    if (drag_id === image._id) return;

    if (isGalleryDnDEvent(dropContainerRef.current) && !classes.contains(placeholderClassName)) {
      const { top, left, width, height } = dropContainerRef.current?.style || {};

      const divEl = document.createElement('div');
      divEl.className = `${placeholderClassName}`;
      divEl.setAttribute(
        'style',
        `top: calc(${top} + 5px); left: ${left}; width: ${width}; height: ${height};`
      );

      divEl.ondrop = (ev) => onDropHandler(ev as any, image._id);

      const parent = dropContainerRef.current.parentElement;
      parent.insertBefore(divEl, dropContainerRef.current);
    }
  };

  const onDragStartHandler = (ev: React.DragEvent) => {
    if (noDrag) return;

    ev.stopPropagation();
    dispatch(setIsImageDarggingAction(ev.currentTarget.getBoundingClientRect()));
    document.body.classList.add(imageInDragBodyClassName);
    CollectionDnDModule.set({
      type: isSelected ? 'multi' : 'image',
      data: {
        drag_id: isSelected ? 'multi' : image._id,
        drop_id: ''
      }
    });
    ev.dataTransfer.setData('drag_id', isSelected ? 'multi' : image._id);
    ev.dataTransfer.effectAllowed = 'move';
    appendMultiSelectContainer(ev, isSelected, dropContainerRef.current, selectedImagesIDs);
    dropContainerRef.current.classList.add('drop-start-image');

    setTimeout(() => {
      dropContainerRef.current.classList.add('hide-image');
      if (isSelected) {
        toggleSelectedImagesInDrag('none');
      }
    });
  };

  const isDraggable = !noDrag && !isMobile();
  const selectedClass = isSelected ? selectedImageClassName : '';
  const isInMultiSelectionClass = isInMultiSelection ? 'multi-selection' : '';

  const removeTitleImage = () => dispatch(setImageTitle({ ...image, _id: '' }));
  const removeSmallScreenTitleImage = () =>
    dispatch(setSmallScreenImageTitle({ ...image, _id: '' }));

  const openSetFocalPointModal = () => {
    const screenType = isTitleImage ? 'desktop' : 'mobile';
    dispatch(
      changeModalState({
        key: 'setFocalPointModal',
        state: true,
        modalData: {
          imageId: image._id,
          defaultFocalPoint: image.focalPoints?.[screenType],
          screenType
        }
      })
    );
  };

  const imageSource = getCollectionImageURL(image, 'XS', false, signedWilCardUrl);

  const verifyImageDimensions = (imgDomObject: any, image: IImage): any => {
    if (image?.extension?.toLowerCase() === 'gif' && image?.width === 0 && image?.height === 0) {
      dispatch(
        setGifMetaData({
          image_id: image._id,
          dimensions: {
            width: imgDomObject.target.naturalWidth,
            height: imgDomObject.target.naturalHeight
          }
        })
      );
    }
  };

  const handleImageLoadError = () =>
    dispatch(showNotifyFail({ message: getTranslationKey('progressUploadModal.imageLoadError') }));

  return (
    <Wrapper
      id={image._id}
      style={style}
      noDrag={noDrag}
      data-cy="wrapper"
      ref={dropContainerRef}
      onDrop={onDropHandler}
      onDragOver={onDragEnterHandler}
      selectedCount={selectedImagesIDs.length}
      className={`${isInMultiSelectionClass} ${selectedClass}`}
    >
      <ImageInner
        data-cy="ImageIner"
        draggable={isDraggable}
        onClick={selectImageHandler}
        onDragStart={onDragStartHandler}
        onDragEnd={partialRight(onDragEndHandler, dropContainerRef)}
        isDraggable={isDraggable}
        className={`${className}`}
        isSelected={isSelected}
      >
        <>
          <ImageCardFavoritesIcons data-cy="ImageCardFavoritesIcons" favorite={favorite} />
          {(isTitleImage || isSmallScreenTitleImage) && (
            <>
              <UncontrolledTooltip
                autohide={false}
                placement="right"
                target="title-image-delete-tooltip"
              >
                {getTranslationKey('galleryImage.deleteTitleImageTooltip')}
              </UncontrolledTooltip>
              <UncontrolledTooltip
                autohide={false}
                placement="right"
                target={'title-image-set-focal-point' + image._id}
              >
                {getTranslationKey('collectionSettings.focalPoint.setFocalPointTooltip')}
              </UncontrolledTooltip>
              <DeleteTitleIcon
                data-cy="DeleteTitleTooltip"
                size={20}
                color="#fff"
                id="title-image-delete-tooltip"
                onClick={isTitleImage ? removeTitleImage : removeSmallScreenTitleImage}
              />
              <StarImage size={20} color="#fff" />
              <SetFocalPointIcon
                fill="#fff"
                id={'title-image-set-focal-point' + image._id}
                onClick={openSetFocalPointModal}
              />
            </>
          )}

          <Cover isSelected={isSelected} />
          {image?.extension?.toLowerCase() === 'gif' ? (
            <Image
              src={imageSource}
              onLoad={(e) => verifyImageDimensions(e, image)}
              onError={handleImageLoadError}
            />
          ) : (
            <Image src={imageSource} onError={handleImageLoadError} />
          )}
        </>
      </ImageInner>

      {!noToolbar && (
        <ImageCardActions
          data-cy="ImageCardActions"
          onOpenInFullscreen={onOpenInFullscreen}
          image={image}
          hideButtons={hideActionsButtons}
          actionID={actionID}
        />
      )}
    </Wrapper>
  );
});
