import { createReducer } from 'redux-act';
import {
  clearGalleries,
  clearLoaders,
  createGallery,
  fetchGalleriesFailed,
  fetchGalleriesSuccess,
  galleryUploadingClearSuccess,
  setCollectionsViewMode,
  setGalleryUploadProgressAction,
  setCurrentGalleryId,
  setImagesToMoveOnCreate
} from '../../../modules/actions';
import { ISetGalleryUploadProgressAction } from '../../../modules/types';

export interface IVideo {
  extension: string;
  fileName: string;
  fileSizeMb: number;
  gallery_id: string;
  id: string;
  identifier: string;
  mimeType: string;
  status: string;
  uploadedAt: string;
  url: string;
  urlExpireDate: string;
  _collection: string;
  _id: string;
  _user: string;
}

export interface IGallery {
  deleteHighResAt: string;
  download: boolean;
  isHidden: boolean;
  sortBy: string;
  forPurchasedImages: boolean;
  sortOrder: 'asc' | 'desc';
  style: string;
  title: string;
  text: string;
  _id: string;
  _watermark: string;
  order?: number;
  _video: IVideo;
  videoEmbedCode: string;
  videoError?: '';
  unfoldState: boolean;
  slideshows: string[];
  currentGalleryId?: string;
  uploadingProcess: {
    start: boolean;
    pause: boolean;
    progress: number;
  };
  videoUploadingProcess: {
    isUploading: boolean;
    progress: number;
  };
}

export type CollectionsViewMode = 'gallery' | 'table';

export interface IGalleriesReducerState {
  items: IGallery[];
  isFetched: boolean;
  isOnCreation: boolean;
  imageIDsMoveOnCreate: string[];
  viewMode: CollectionsViewMode;
  currentGalleryId: string;
}

const initialState: IGalleriesReducerState = {
  items: [],
  isFetched: false,
  isOnCreation: false,
  imageIDsMoveOnCreate: [],
  currentGalleryId: '',
  viewMode: (localStorage.getItem('collections:viewMode') as CollectionsViewMode) || 'table'
};

export const galleries = createReducer<IGalleriesReducerState>({}, initialState);
galleries.on(fetchGalleriesSuccess, (state: IGalleriesReducerState, payload: IGallery[]) => ({
  ...state,
  items: payload,
  isFetched: true,
  isOnCreation: false
}));
galleries.on(
  galleryUploadingClearSuccess,
  (state: IGalleriesReducerState, payload: IGallery[]) => ({
    ...state,
    items: payload,
    isFetched: true,
    isOnCreation: false
  })
);
galleries.on(createGallery, (state: IGalleriesReducerState) => ({
  ...state,
  isOnCreation: true
}));
galleries.on(clearLoaders, (state: IGalleriesReducerState) => ({
  ...state,
  isFetched: true,
  isOnCreation: false
}));
galleries.on(setImagesToMoveOnCreate, (state: IGalleriesReducerState, payload) => ({
  ...state,
  imageIDsMoveOnCreate: payload
}));
galleries.on(fetchGalleriesFailed, () => initialState);
galleries.on(clearGalleries, () => initialState);
galleries.on(setCollectionsViewMode, (state, payload) => ({
  ...state,
  viewMode: payload
}));
galleries.on(setCurrentGalleryId, (state, payload) => ({
  ...state,
  currentGalleryId: payload
}));

galleries.on(
  setGalleryUploadProgressAction,
  (state: IGalleriesReducerState, payload: ISetGalleryUploadProgressAction) => ({
    ...state,
    items: state.items.map((gallery) => {
      if (gallery._id !== payload.galleryId) return gallery;

      return {
        ...gallery,
        uploadingProcess: {
          ...gallery.uploadingProcess,
          ...payload.uploadingProcess
        }
      };
    })
  })
);
