import React from 'react';
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
/* tslint:disable */
import Texts from '../../json/texts.json';
import styled from 'styled-components';
import { IImage } from '../../modules/reducers/images';
import { useDispatch } from 'react-redux';
import {
  changeModalState,
  deleteImage,
  setImageTitle,
  setSmallScreenImageTitle
} from '../../modules/actions';
import theme from '../../assets/css/theme';
import { getTranslationKey } from '../../modules/utils/helpers';
import { omit } from 'lodash';
import { FaExpandArrowsAlt, FaMobileAlt } from 'react-icons/fa';

const ButtonsWrapper = styled(ButtonGroup)`
  position: absolute;
  bottom: 30px;
  display: none;
  z-index: 2;
`;

const ButtonItem = styled(({ ...rest }) => {
  const btProps = omit(rest, 'isHidden');

  return <Button {...btProps} />;
})<{ isHidden: boolean }>`
  position: relative;
  background: ${theme.formsColors.secondBackground};
  color: ${theme.formsColors.secondColor};
  border: none;
  display: ${(props) => (props.isHidden ? 'none' : 'inline-flex')};
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  padding: 0;
`;

export type ActionButtonsTypes =
  | 'title'
  | 'smallScreenTitle'
  | 'crop'
  | 'info'
  | 'download'
  | 'watermark'
  | 'delete'
  | 'fullscreen';

interface IProps {
  image: IImage;
  hideButtons?: ActionButtonsTypes[];
  onOpenInFullscreen?: Function;
  actionID?: string;
}

export const ImageCardActions = ({
  image,
  onOpenInFullscreen,
  hideButtons = [],
  actionID
}: IProps) => {
  const dispatch = useDispatch();
  const { originalImageName, _collection, _id } = image;
  const titleId = `${actionID}_title_${image._id}`;
  const smallScreenTitleId = `${actionID}_small_screen_title_${image._id}`;
  const fullscreenId = `${actionID}_fullscreen_${image._id}`;
  const cropId = `${actionID}_tcrop_${image._id}`;
  const infoId = `${actionID}_tinfo_${image._id}`;
  const downloadId = `${actionID}_tdownload_${image._id}`;
  const waterID = `${actionID}_twater_${image._id}`;
  const deleteID = `${actionID}_tdelete_${image._id}`;
  const setTitleImageHandler = () => {
    dispatch(setImageTitle(image));
  };

  const setSmallScreenTitleImageHandler = () => {
    dispatch(setSmallScreenImageTitle(image));
  };
  const deleteImageHandler = () => {
    dispatch(deleteImage(image._id));
  };
  const preventPropagation = (e: any) => {
    e.stopPropagation();
  };
  const setWatermarkHandler = () => {
    dispatch(
      changeModalState({
        key: 'selectWaterMarkModal',
        state: true,
        modalData: {
          watermarkType: 'image',
          targetID: image._id,
          watermarkID: image._watermark
        }
      })
    );
  };
  const cropImageHandler = () => {
    dispatch(
      changeModalState({
        key: 'cropImageModal',
        state: true,
        modalData: {
          targetID: image._id
        }
      })
    );
  };
  const checkIsButtonHidden = (buttonName: ActionButtonsTypes) =>
    hideButtons?.indexOf(buttonName) > -1;

  return (
    <ButtonsWrapper onClick={preventPropagation} className="image-tools">
      <ButtonItem
        id={titleId}
        size="sm"
        color="primary"
        onClick={setTitleImageHandler}
        isHidden={checkIsButtonHidden('title')}
      >
        <i className="icon-picture" />
        <UncontrolledTooltip placement="top" target={titleId}>
          {/* @ts-ignore */}
          {Texts[window.LANGUAGE_SW].gallerySettings.imageActions.titleImage}
        </UncontrolledTooltip>
      </ButtonItem>
      <ButtonItem
        id={smallScreenTitleId}
        size="sm"
        color="primary"
        onClick={setSmallScreenTitleImageHandler}
        isHidden={checkIsButtonHidden('smallScreenTitle')}
      >
        <FaMobileAlt />
        <UncontrolledTooltip placement="top" target={smallScreenTitleId}>
          {/* @ts-ignore */}
          {Texts[window.LANGUAGE_SW].gallerySettings.imageActions.smallScreenTitleImage}
        </UncontrolledTooltip>
      </ButtonItem>
      <ButtonItem
        id={cropId}
        size="sm"
        color="primary"
        onClick={cropImageHandler}
        isHidden={checkIsButtonHidden('crop')}
      >
        <i className="icon-frame" />
        <UncontrolledTooltip placement="top" target={cropId}>
          {/* @ts-ignore */}
          {Texts[window.LANGUAGE_SW].gallerySettings.imageActions.appIcon}
        </UncontrolledTooltip>
      </ButtonItem>
      <ButtonItem id={infoId} size="sm" color="primary" isHidden={checkIsButtonHidden('info')}>
        <i data-cy="icon-info" className="icon-info" />
        <UncontrolledTooltip placement="top" target={infoId}>
          {originalImageName}
        </UncontrolledTooltip>
      </ButtonItem>
      <ButtonItem
        id={downloadId}
        size="sm"
        color="primary"
        // @ts-ignore
        href={`${window.API_URL_DL}/api/image/download/${_collection}/${_id}?q=high`}
        isHidden={checkIsButtonHidden('download')}
      >
        <i className="icon-cloud-download" />
        <UncontrolledTooltip placement="top" target={downloadId}>
          {/* @ts-ignore */}
          {Texts[window.LANGUAGE_SW].downloadOriginalImage}
        </UncontrolledTooltip>
      </ButtonItem>
      {typeof onOpenInFullscreen === 'function' && (
        <ButtonItem
          id={fullscreenId}
          size="sm"
          color="primary"
          onClick={onOpenInFullscreen}
          isHidden={checkIsButtonHidden('fullscreen')}
        >
          <FaExpandArrowsAlt />
          <UncontrolledTooltip placement="top" target={fullscreenId}>
            {/* @ts-ignore */}
            {Texts[window.LANGUAGE_SW].gallerySettings.imageActions.fullscreen}
          </UncontrolledTooltip>
        </ButtonItem>
      )}
      <ButtonItem
        id={waterID}
        size="sm"
        color="primary"
        onClick={setWatermarkHandler}
        isHidden={checkIsButtonHidden('watermark')}
      >
        <i className="icon-drop" />
        <UncontrolledTooltip placement="top" target={waterID}>
          {getTranslationKey('watermark.watermark')}
        </UncontrolledTooltip>
      </ButtonItem>
      <ButtonItem
        id={deleteID}
        size="sm"
        color="danger"
        onClick={deleteImageHandler}
        isHidden={checkIsButtonHidden('delete')}
      >
        <i className="icon-trash" data-cy="icon-trash" />
        <UncontrolledTooltip placement="top" target={deleteID}>
          {/* @ts-ignore */}
          {Texts[window.LANGUAGE_SW].gallerySettings.imageActions.delete}
        </UncontrolledTooltip>
      </ButtonItem>
    </ButtonsWrapper>
  );
};
