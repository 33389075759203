import { useDispatch, useSelector } from 'react-redux';
import { getSubscription } from '../../../modules/selectors/subscription';
import { updatePlan } from '.';
import { useEffect } from 'react';
import {
  calculateDiscountAction,
  fetchPaymentInfoAction,
  fetchPricingAction
} from '../../../modules/actions';
import { useSubscriptionCurrency } from '../../../modules/hooks/useSubscriptionCurrency';
import { find, get } from 'lodash';
import { getUser } from '../../../modules/selectors';
import { getPaymentInfo } from '../../../modules/selectors/paymentInfo';
import { IPaymentInfo } from '../../../modules/types/paymentInfo';

export interface ISubscriptionBreakdown {
  currency: string;
  plan: string;
  billingCycle: 'monthly' | 'yearly';
  prorations: IPaymentInfo['prorations'];
  corePlan: {
    priceId: string;
    productId: string;
    plan: string;
    displayName: string;
    price: number;
  };
  slideshow: {
    priceId: string;
    displayName: string;
    price: number;
  };
  videoStorage: {
    priceId: string;
    displayName: string;
    plan: string;
    price: number;
  };
  brands: {
    priceId: string;
    displayName: string;
    price: number;
    quantity: number;
  };
  domains: {
    priceId: string;
    displayName: string;
    price: number;
    quantity: number;
  };
  templates: {
    priceId: string;
    displayName: string;
    templateId: string;
    price: number;
  }[];
  totalTemplatesGross: number;
  discount: number;
  totalNet: number;
  tax: number;
  balance: number;
  totalGross: number;
  totalSubscriptionGross: number;
  pricingIsLoading: boolean;
}

export function useSubscriptionBreakdown() {
  const dispatch = useDispatch();
  const { subscriptionCurrency } = useSubscriptionCurrency();
  const user = useSelector(getUser);
  const { pricing, discount: calculatedDiscount, prorations } = useSelector(getPaymentInfo);
  const { plan, billingCycle, videoStorage, templates, slideshows, brandsAmount, domainsAmount } =
    useSelector(getSubscription);
  const newPlan = updatePlan(plan);
  const discountKeyId = get(user, 'digistore.discountKey._id', '');

  useEffect(() => {
    dispatch(fetchPaymentInfoAction());
    dispatch(fetchPricingAction());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!discountKeyId) return;
    dispatch(
      calculateDiscountAction({
        subscriptionConfig: {
          interval: billingCycle,
          plan: newPlan,
          templates: templates || [],
          currency: subscriptionCurrency
        },
        discountId: discountKeyId
      })
    );
  }, [discountKeyId, billingCycle, newPlan, templates, subscriptionCurrency]);

  const slideshowPrice =
    get(pricing, `addonPricing.slideshows.unlimited.${billingCycle}PriceCents`, 0) / 100;
  const isProfessionalPlan = newPlan === 'professional';
  const domainsIncluded = isProfessionalPlan ? 1 : 0;
  const brandsIncluded = 1;
  const selectedTemplates = (templates || [])
    .map((id) => find(pricing?.templates, { templateId: id }))
    .filter((template) => template)
    .map((template) => ({
      ...template,
      priceId: template.priceStripeId,
      price: template.priceCents / 100
    }));

  const coreSubscriptionDiscount: number =
    (calculatedDiscount?.firstCorePaymentDiscount ?? 0) / 100;
  const discountTemplate = find(pricing.templates, { templateId: calculatedDiscount?.templateId });
  const templateDiscount: number = discountTemplate ? discountTemplate.priceCents / 100 : 0;
  const slideshowDiscount: number = calculatedDiscount?.freeSlideshow ? slideshowPrice : 0;

  const breakdown: ISubscriptionBreakdown = {
    currency: subscriptionCurrency,
    billingCycle,
    prorations,
    plan,
    corePlan: {
      plan: newPlan,
      displayName: get(pricing, `corePricing.${newPlan}.displayName`, ''),
      price: get(pricing, `corePricing.${newPlan}.${billingCycle}PriceCents`, 0) / 100,
      priceId: get(pricing, `corePricing.${newPlan}.${billingCycle}PriceStripeId`, ''),
      productId: get(pricing, `corePricing.${newPlan}.productStripeId`, '')
    },
    slideshow: slideshows
      ? {
          price: slideshowPrice,
          displayName: get(pricing, `addonPricing.slideshows.unlimited.displayName`, ''),
          priceId: get(
            pricing,
            `addonPricing.slideshows.unlimited.${billingCycle}PriceStripeId`,
            ''
          )
        }
      : null,
    videoStorage: videoStorage
      ? {
          plan: videoStorage,
          displayName: get(pricing, `addonPricing.video-storage.${videoStorage}.displayName`, ''),
          price:
            get(
              pricing,
              `addonPricing.video-storage.${videoStorage}.${billingCycle}PriceCents`,
              0
            ) / 100,
          priceId: get(
            pricing,
            `addonPricing.video-storage.${videoStorage}.${billingCycle}PriceStripeId`,
            ''
          )
        }
      : null,
    brands:
      !!brandsAmount && brandsAmount > 1
        ? {
            price:
              ((brandsAmount - brandsIncluded) *
                get(pricing, `addonPricing.brands.${billingCycle}PriceCents`, 0)) /
              100,
            displayName: get(pricing, `addonPricing.brands.displayName`, ''),
            priceId: get(pricing, `addonPricing.brands.${billingCycle}PriceStripeId`, ''),
            quantity: brandsAmount
          }
        : null,
    domains:
      domainsAmount > (isProfessionalPlan ? 1 : 0)
        ? {
            price:
              ((domainsAmount - domainsIncluded) *
                get(pricing, `addonPricing.brands.${billingCycle}PriceCents`, 0)) /
              100,
            displayName: get(pricing, `addonPricing.domains.displayName`, ''),
            priceId: get(pricing, `addonPricing.domains.${billingCycle}PriceStripeId`, ''),
            quantity: domainsAmount
          }
        : null,
    templates: selectedTemplates,
    totalTemplatesGross:
      selectedTemplates.reduce((a, b) => a + b.price, 0) *
      (1 + user.taxSettings.platformPercent / 100),
    totalNet: 0,
    discount: coreSubscriptionDiscount + templateDiscount + slideshowDiscount,
    tax: 0,
    balance: 0,
    totalGross: 0,
    totalSubscriptionGross: 0,
    pricingIsLoading: Object.keys(pricing.corePricing).length === 0
  };

  breakdown.totalNet =
    breakdown.corePlan.price +
    get(breakdown, 'videoStorage.price', 0) +
    breakdown.templates.map((t) => t.price).reduce((a, b) => a + b, 0) +
    get(breakdown, 'slideshow.price', 0) +
    get(breakdown, 'brands.price', 0) +
    get(breakdown, 'domains.price', 0) -
    breakdown.discount;

  breakdown.tax = (breakdown.totalNet * user.taxSettings.platformPercent) / 100;
  breakdown.balance = 0;
  breakdown.totalGross = Math.floor(breakdown.totalNet * 100 + breakdown.tax * 100) / 100;
  breakdown.totalSubscriptionGross = breakdown.totalGross - breakdown.totalTemplatesGross;

  return breakdown;
}
