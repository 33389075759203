import { css } from 'styled-components';
import theme from './theme';

export default css`
  html,
  body {
    color: ${theme.textColors.baseText};
    background-color: ${theme.commonColors.secondBackground};
  }

  a {
    color: ${theme.textColors.link};
  }

  .dnd-placeholder {
    padding: 5px 10px;
    position: absolute;
    z-index: 10;
    border-radius: 5px;
    border: 3px dotted ${theme.commonColors.second};
  }

  .image-drag-enabled {
    .scroll-collection-area {
      display: block;
    }
  }
`;
