import React from 'react';
import { getTranslationKey } from '../../modules/utils/helpers';
import { downloadFile } from '../../helpers/globalFunctions';
import { FaDownload, FaImages, FaUndo } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { getShopOrdersSelector } from '../../modules/selectors';
import { SHOP_ORDER_STATUS } from '../../modules/types';

interface IProps {
  orderId: string;
  invoiceDownloadLink: string;
  openImagesModal: (orderId: string) => void;
  openCancelOrderModal: (orderId: string) => void;
}

export const OrdersActionsButton = ({
  orderId,
  invoiceDownloadLink,
  openImagesModal,
  openCancelOrderModal
}: IProps) => {
  const orders = useSelector(getShopOrdersSelector);
  const order = orders.list.find((o) => o.id === orderId);
  const downloadInvoices = () => {
    downloadFile(invoiceDownloadLink);
  };

  return (
    <div>
      <FaImages
        className="color-primary"
        style={{ cursor: 'pointer' }}
        onClick={() => openImagesModal(orderId)}
        title={getTranslationKey('shop.orders.showImagesButton')}
      />
      {order.status !== SHOP_ORDER_STATUS.CANCELED && (
        <FaUndo
          className="color-primary ml-3"
          style={{ cursor: 'pointer' }}
          onClick={() => openCancelOrderModal(orderId)}
          title={getTranslationKey('shop.orders.cancelOrderButton')}
        />
      )}
      {invoiceDownloadLink && (
        <FaDownload
          className="color-primary ml-3"
          style={{ cursor: 'pointer' }}
          onClick={downloadInvoices}
          title={getTranslationKey('shop.orders.downloadInvoicesButton')}
        />
      )}
    </div>
  );
};
