import Api from '../../utils/API';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { putAccessRules } from '../../actions';

export const accessRulesSaga = function* (): SagaIterator {
	try {
		const { result: availableRules } = yield call(Api.AccessRules.get);
    const { result: planConfig } = yield call(Api.AccessRules.getPlanConfig);
    const { result: planUsage } = yield call(Api.AccessRules.getPlanUsage);

    yield put(putAccessRules({ availableRules, planConfig, planUsage }));
	} catch (e) {
		console.error("Failed to track event", e);
	}
};
